<template>
    <Navbar user="0"/>
    <section class="courses-details-area pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="courses-details-desc text-start">
              <h2 class="text-center">{{ test.title }}</h2>
              <div>
                <h4 class="text-center">Description</h4>
                <span v-html="test.description"></span>
                <h4 class="text-center">Instructions</h4>
                <div class="text-center">
                  <SelfTestInstruction :test="test" />
                </div>
                <div class="text-center d-grid gap-2 d-md-block">
                    <button class="default-btn btn me-2" @click="takeTest(test.id)" >Take A Test</button>
                </div>
              </div>         
            </div>
            
          </div>

        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import moment from 'moment'
import SelfTestInstruction from '@/components/test/SelfTestInstruction.vue'
export default {
 components: { Navbar, Loading, SelfTestInstruction },
  data(){
    return {
      isLoading: true,
      showInstruction: false,
      subscriptionStatus: 0,
      rating: 4.3,
      user:{},
      test: {},
      question: {},
      test_results:{},
      token: localStorage.getItem('user_data'),
      errors: []
    }
  },
  methods:{
    takeTest(id){
      axios.post('https://apitraining.vipawaworks.com/api/self_test_question/get_test_fist_question/' + id).then(response => {
        this.question = response.data
        this.$router.push({ name: 'SelfTestsQuestion', params: {id: this.question.id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getTest(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/self_test/get_test/' + id).then(response => {
        this.test = response.data
        document.title = this.test.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getTest() 
    this.moment = moment
  }
}
</script>

<style scope>
.list-group-item {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 0px solid rgba(0,0,0,.125);
}
</style>